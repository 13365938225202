<template>
  <div>
      <div class="footer">
      <div class="foot_top">
        <div class="foot_area">
          <div class="foot_top_logo">
            <img src="../img/logodown.jpg" alt="" />
          </div>
          <div class="foot_top_detail">
            <ul class="detail_content">
              <li class="detail_content_list">
                <div class="detail_content_son">
                  <h4 @click="toSone">高端网站定制</h4>
                  <ul class="son1">
                    <li>企业&品牌官网</li>
                    <li>大型上市集团</li>
                    <li>门户型网站</li>
                    <li>营销型网站</li>
                  </ul>
                </div>
              </li>
              <li class="detail_content_list">
                <div class="detail_content_son">
                  <h4 @click="toStwo">响应式网站</h4>
                  <ul class="son1">
                    <li>四站合一</li>
                    <li>炫酷H5</li>
                    <li>超速加载</li>
                    <li>利于优化</li>
                  </ul>
                </div>
              </li>
              <li class="detail_content_list">
                <div class="detail_content_son">
                  <h4 @click="toSthree">移动互联网</h4>
                  <ul class="son1">
                    <li>手机网站</li>
                    <li>微商城</li>
                    <li>小程序</li>
                    <li>APP应用</li>
                  </ul>
                </div>
              </li>
              <li class="detail_content_list">
                <div class="detail_content_son">
                  <h4 @click="toSfour">运营维护</h4>
                  <ul class="son1">
                    <li>网站运维托管</li>
                    <li>搜索引擎SEO优化</li>
                    <li>网站代运营</li>
                    <li>微信代运营</li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="foot_top_relation">
            <h4>联系我们</h4>
            <p><span class="iconfont yf-icon-dianhua"></span>咨询热线：18811547597</p>
            <p><span class="iconfont yf-icon-daohangdizhi"></span>公司地址：北京市朝阳区双惠苑甲5号A座1005室</p>
          </div>
        </div>
      </div>
      <div class="foot_bottom">
        <div class="foot_bottom_left">
          <span @click="toRegard">关于我们</span>&nbsp;&nbsp;  |  &nbsp;&nbsp;<span @click="toRelation">联系我们</span>
        </div>
        <div class="foot_bottom_right">
          <span>版权所有 © 1999-2022 盈锋科技<a v-bind:href="url" target="_blank"><span class="foot_bottom_right_num"> 晋ICP备2021014702号-1</span></a></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {} from "../http/api";
export default {
  components: {},
  props: [],
  data() {
    return {
      url:"https://beian.miit.gov.cn/#/Integrated/index",
    };
  },
  methods: {
    toRegard(){
      this.$router.push({ path: "/regard"});
    },
    toRelation(){
      this.$router.push({ path: "/relation"});
    },
    toSone() {
      this.$router.push({ path: "/sone" });
    },
    toStwo() {
      this.$router.push({ path: "/stwo" });
    },
    toSthree() {
      this.$router.push({ path: "/sthree" });
    },
    toSfour() {
      this.$router.push({ path: "/sfour" });
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>
<style scoped lang="less">
/* 底部 */
.footer {
  // height: 250px;
  width: 100%;
}
.foot_top {
  // height: 200px;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
.foot_area {
  height: 100%;
  width: 90%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  margin: 3% auto;
  flex-wrap: wrap;

}
.foot_top_logo {
  margin-top: 1.5%;
  width: 260px;
  height: 100%;
}
.foot_top_logo img {
  display: block;
  margin: 0 auto;
  width: 150px;
  height: 100px;
}
.detail_content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

}
.detail_content .detail_content_list {
  margin-left: 100px;
}
.detail_content_son h4{
  font: 14px/50px"微软雅黑";
  cursor: pointer;
}
.detail_content_son .son1 li{
  font: 12px/26px"微软雅黑";
  color: #8d8d8d;
}

.foot_top_relation h4 {
  font: 16px/50px "微软雅黑";
}
.foot_top_relation p  {
  font: 12px/26px "微软雅黑";
  color: #8d8d8d;
}
.foot_top_relation p span {
  font: 15px/26px;
  color: #3a6cfe;
  margin-right: 10px;
}
.foot_bottom {
  height: 50px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;

}
.foot_bottom_left{
    font: 12px/50px "微软雅黑";
  color: #8d8d8d;
}
.foot_bottom_left span {
  font: 12px/50px "微软雅黑";
  color: #8d8d8d;
  cursor: pointer;
}
.foot_bottom_right span {
  font: 12px/50px "微软雅黑";
  color: #8d8d8d;
}
.foot_bottom_right .foot_bottom_right_num {
  font: 12px/50px "微软雅黑";
  color: #3a6cfe;
  cursor: pointer;
}
.foot_top_detail{
  margin-right: 100px;
}
/* 响应式布局 */
@media screen and (max-width: 1520px) {
.detail_content .detail_content_list{
  margin-left: 72px;
}

}
@media screen and (max-width: 1400px) {
.foot_top_relation{
  display: none;
}
}
@media screen and (max-width: 1100px) {
  .foot_top_detail{
  display: none;
}
.foot_top_relation{
  display: block;
}

}
@media screen and (min-width: 992px) {
  .header_list .hlist_content li:hover::after {
    left: 55px;
  }
}
@media screen and (max-width: 992px) {
  .header_see {
    width: 15%;
    margin-right: 30px;
  }
  .code {
    left: 15%;
  }
  .logo {
    width: 20%;
  }
  .header_list {
    width: 50%;
  }
  .card_ul .card_li {
    width: 45%;
  }
  .c_list ul li {
    width: 45%;
  }
  .foot_top_logo{
    width: 30%;
  }
  .foot_top_detail{
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .header_see {
    display: none;
  }
  .logo {
    width: 20%;
  }
  .header_list {
    width: 70%;
  }
  .card_ul .card_li {
    width: 100%;
  }
  .c_list ul li {
    width: 100%;
  }
  .foot_top_logo{
    display: none;
  }
 
}
@media screen and (max-width: 600px) {
  .header_see {
    display: none;
  }
  .logo {
    width: 20%;
  }
  .header_list {
    width: 70%;
  }
  .card_ul .card_li {
    width: 100%;
  }
  .c_list ul li {
    width: 100%;
  }
  .foot_top_logo{
    display: none;
  }
  .detail_content{
    display: none;
  }
  .foot_bottom_left{
    display: none;
  }
 
}
</style>