import { render, staticRenderFns } from "./pFoot.vue?vue&type=template&id=070a4a6b&scoped=true&"
import script from "./pFoot.vue?vue&type=script&lang=js&"
export * from "./pFoot.vue?vue&type=script&lang=js&"
import style0 from "./pFoot.vue?vue&type=style&index=0&id=070a4a6b&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070a4a6b",
  null
  
)

export default component.exports