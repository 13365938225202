import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// pages 路由组件

// components 公共组件
import "./components"

// filters 过滤器
import "./filters"

// assets 静态资源
import "./assets/css/reset.css"
import "./assets/icon/iconfont.css"

// less 预处理器

// 数据交互  安装axios 配置代理  封装

// element-ui 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from "vue-baidu-map";

Vue.use(BaiduMap,{
  ak:"jRei6AdBGRmGTrC94C7vohLZTCzwqdq0"
})

//引入百度地图
Vue.use(ElementUI);

// mixins 混入

// store 仓库

// utils 工具

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
