import axios from "axios";
const instance = axios.create({
    baseURL:"https://shop.shanxiyf.com/",
    timeout:5000
})

export function get(url,params){
    return instance.get(url,{
        params
    })
}


export function post(url,params){
    return instance.post(url,params,{
        transformRequest:[
            function(data){
                let ret = "";
                for(let it in data){
                    ret += encodeURIComponent(it)+
                    "="+ encodeURIComponent(data[it])+ "&";
                }
                return ret;
            }
        ],headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        }
    })
}