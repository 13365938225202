import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import("../pages/index/index"),
    meta:{
      title:"盈锋科技"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  },
  {
    path: "/regard",
    component: () => import("../pages/regard/regard.vue"),
    meta:{
      title:"关于我们"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  }, {
    path: "/serve",
    component: () => import("../pages/serve/serve.vue"),
    meta:{
      title:"服务"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  }, {
    path: "/case",
    component: () => import("../pages/case/case.vue"),
    meta:{
      title:"案例"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  },{
    path: "/casedetail",
    component: () => import("../pages/casedetail/casedetail.vue"),
    meta:{
      title:"案例详情"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  }, {
    path: "/news",
    component: () => import("../pages/news/news.vue"),
    meta:{
      title:"新闻详情"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  },{
    path: "/newdetail",
    component: () => import("../pages/newdetail/newdetail.vue"),
    meta:{
      title:"新闻"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  }, {
    path: "/relation",
    component: () => import("../pages/relation/relation.vue"),
    meta:{
      title:"联系我们"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈锋科技'
      }
      next()
    })
  }, {
    path: "/sone",
    component: () => import("../pages/sone/sone.vue")
  }, {
    path: "/stwo",
    component: () => import("../pages/stwo/stwo.vue")
  }, {
    path: "/sthree",
    component: () => import("../pages/sthree/sthree.vue")
  }, {
    path: "/sfour",
    component: () => import("../pages/sfour/sfour.vue")
  }, {
    path: "/sfive",
    component: () => import("../pages/sfive/sfive.vue")
  },{
    path: "/search",
    component: () => import("../pages/search/search.vue")
  },
  {
    path: "*",
    redirect: "/",
    meta:{
      title:"盈峰科技"
    },
    beforeEnter:((to,from,next)=>
    {
      if (to.meta.title) {
        document.title = to.meta.title
      } else {
        document.title = '盈峰科技'
      }
      next()
    })
  }
]
// routes.beforeEach((to,from,next)=>{
//   if(to.meta.title){
//     document.title = to.meta.title
//   }else{
//     document.title = '盈峰'
//   }
// })

const router = new VueRouter({
  // history模式，去除URL中
  routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior() {
    return {x: 0, y: 0};
  }
})

export default router
