import Vue from 'vue'
import pHead from "./pHead.vue"
import pFoot from "./pFoot.vue"


let Components= {
     pHead,
    pFoot
}

for(let key in Components){
    Vue.component(key,Components[key])
}