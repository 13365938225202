<template>
  <div class="co">
    <div class="header">
      <div class="logo2">
        <img src="../img/logoaa.jpg" alt="" />
      </div>
      <div class="logo">
        <img src="../img/logoaaaaa.jpg" alt="" />
      </div>
      <div class="header_list">
        <ul class="hlist_content" v-show="isShow2">
          <!-- <li
            v-for="(item, id) in navData"
            :class="{ active1: active === id }"
            :key="id"
            @click="(active = id), $router.push({ path: item.path })"
          >
            {{ item.name }}
          </li> -->
          <li :class="{ active: '/' === $route.path }" @click="goTo('/')">
            首页
          </li>
          <li
            :class="{ active: '/regard' === $route.path }"
            @click="goTo('/regard')"
          >
            关于
          </li>
          <li class="xialacaidan">
            <el-row class="block-col-2">
              <el-dropdown>
                <span
                  :class="{ active: '/serve' === $route.path }"
                  @click="goTo('/serve')"
                >
                  服务
                </span>
                <el-dropdown-menu slot="dropdown">
                  <ul class="navnav">
                    <li @click="goTo('/sone')">高端网站定制</li>
                    <li @click="goTo('/stwo')">响应式网站</li>
                    <li @click="goTo('/sthree')">移动互联网</li>
                    <li @click="goTo('/sfour')">运营维护</li>
                  </ul>
                </el-dropdown-menu>
              </el-dropdown>
            </el-row>
          </li>

          <li class="xialacaidan">
            <el-row class="block-col-2">
              <el-dropdown>
                <span
                  :class="{ active: '/case' === $route.path }"
                  @click="goTo('/case')"
                >
                  案例
                </span>
                <el-dropdown-menu slot="dropdown">
                  <ul class="navnav">
                    <template v-for="name in case_nav">
                      <li @click="gocase(name.id)">{{ name.name }}</li>
                    </template>
                  </ul>
                </el-dropdown-menu>
              </el-dropdown>
            </el-row>
          </li>

          <li class="xialacaidan">
            <el-row class="block-col-2">
              <el-dropdown>
                <span
                  :class="{ active: '/news' === $route.path }"
                  @click="goTo('/news')"
                >
                  新闻
                </span>
                <el-dropdown-menu slot="dropdown">
                  <ul class="navnav">
                    <template v-for="name in new_nav">
                      <li @click="gonews(name.id)">{{ name.name }}</li>
                    </template>
                  </ul>
                </el-dropdown-menu>
              </el-dropdown>
            </el-row>
          </li>
          <li
            :class="{ active: '/relation' === $route.path }"
            @click="goTo('/relation')"
          >
            联系
          </li>
        </ul>

        <div class="search-input" v-show="isShow">
          <div class="exit">
            <span class="iconfont yf-icon-cha" @click="cha"></span>
          </div>
          <input type="text" name="" id="" value="" v-model="searchContent" />
          <div
            class="search-do iconfont yf-icon-sousuo"
            @click="toSearch"
          ></div>
          <ul class="search-more" v-show="isShow3">
            <li>热门搜索</li>
            <li @click="handelclick('网站制作')">
              <a href="javascript:;">网站制作</a>
            </li>
            <li @click="handelclick('网站开发')">
              <a href="javascript:;">网站开发</a>
            </li>
            <li @click="handelclick('H5页面')">
              <a href="javascript:;">H5页面</a>
            </li>
            <li @click="handelclick('新闻')">
              <a href="javascript:;">新闻</a>
            </li>
            <li @click="handelclick('网站设计')">
              <a href="javascript:;">网站设计</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="header_see">
        <ul class="see_list">
          <li @click="change" class="iconfont yf-icon-sousuo"></li>
          <li
            class="iconfont yf-icon-weixin"
            v-on:mouseover="changeCode($event)"
            v-on:mouseout="removeCode($event)"
          ></li>
          <a
            href="http://wpa.qq.com/msgrd?v=3&uin=619990400&site=qq&menu=yes"
            target="_blank"
          >
            <li class="iconfont yf-icon-QQ"></li
          ></a>
        </ul>

        <div class="code" v-show="code">
          <img class="codepic" src="../img/code.jpg" alt="" />
        </div>
      </div>

      <div class="menu">
        <template>
          <div class="iconfont yf-icon-caidan" @click="drawer = true"></div>
          <el-drawer
            title="我是标题"
            :visible.sync="drawer"
            :with-header="false"
            :size="size"
          >
            <ul class="menu_list">
              <li>
                <router-link to="/index">首页</router-link>
              </li>
              <li>
                <router-link to="/regard">关于</router-link>
              </li>
              <li>
                <router-link to="/serve">服务</router-link>
              </li>
              <li>
                <router-link to="/case">案例</router-link>
              </li>
              <li>
                <router-link to="/news">新闻</router-link>
              </li>
              <li>
                <router-link to="/relation">联系</router-link>
              </li>
            </ul>
          </el-drawer>
        </template>
      </div>
    </div>
   
  </div>
</template>
<script>
import { get, post } from "../http/request";
import {} from "../http/api";
export default {
  components: {},
  props: [],
  data() {
    return {
      code: false,
      nav: false,
      nav1: false,
      nav2: false,
      isShow: false,
      isShow2: true,
      isShow3: true,
      searchContent: "",
      logoup: "",
      drawer: false,
      size: "40%",
      active: "",
      navData: [
        { id: 1, name: "首页", path: "/index" },
        { id: 2, name: "关于", path: "/regard" },
        { id: 3, name: "服务", path: "/serve" },
        { id: 4, name: "案例", path: "/case" },
        { id: 5, name: "新闻", path: "/news" },
        { id: 6, name: "联系", path: "/relation" },
      ],
      case_nav: [],
      new_nav: [],
    };
  },
  methods: {
    goTo(path) {
      this.$router.replace(path);
    },
    gocase(case_id) {
      if (this.$router.app._route.path == "/case") {
        this.$emit("addnum", case_id); //父类方法addnum
      } else {
        this.$router.push("/case?case_id=" + case_id);
      }
    },
    gonews(news_id) {
      if (this.$router.app._route.path == "/news") {
        this.$emit("addnum", news_id); //父类方法addnum
      } else {
        this.$router.push("/news?news_id=" + news_id);
      }
    },
    handelclick(val) {
      this.searchContent = val;
      this.isShow3 = false;
    },
    change() {
      (this.isShow = !this.isShow), (this.isShow2 = !this.isShow2);
      this.isShow3 = true;
    },
    cha() {
      (this.isShow = !this.isShow), (this.isShow2 = !this.isShow2);
      this.searchContent = "";
    },
    toSearch() {
      if (this.searchContent) {
        this.$emit("getcontent", this.searchContent);
        if (this.$route.path != "/search") {
          this.$router.push({
            path: "/search",
            query: { qkeyword: this.searchContent },
          });
        }
      }
      this.isShow3 = false;

      // this.cha()
    },
    torelation() {
      this.$router.push({ path: "/relation" });
    },
    bg() {
      this.active = active;
    },
    changeCode($event){
this.code = !this.code
    },
    removeCode($event){
this.code = !this.code
    },
  
  },
  mounted() {
    get("/api/home/getall", { page: 1, limit: 1 })
      .then((res) => {
        if (res.data.code == 200) {
          this.case_nav = res.data.case.case_nav;
          this.new_nav = res.data.news.new_nav;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {},
};
</script>
<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 100px;
  /* background-color: pink; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_list .hlist_content li a {
  text-decoration: none;
  color: #333333;
}

.router-link-active {
  text-decoration: none;
}
.logo {
  width: 10%;
  margin-left: 5%;
  overflow: hidden;
}

.logo2 {
  display: none;
}
.header_list {
  height: 100%;
}
.header_list .hlist_content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_list .hlist_content li {
  position: relative;
  height: 100%;
  width: 126px;
  text-align: center;
  font-size: 16px;
  line-height: 100px;
  cursor: pointer;
  a {
    color: black;
  }
}
yf-icon-weixin:hover {
  width: 100px;
  height: 100px;
  background-color: #3d6afe;
}
.header_list .hlist_content li:hover {
  cursor: pointer;
  width: 126px;
  text-align: center;
  font-size: 16px;
  color: #3a6cfe;
}
.header_list .hlist_content li:hover::after {
  content: "";
  display: block;
  position: absolute;
  top: 70%;
  left: 37%;
  width: 0;
  height: 0px;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  border: 8px;
  border-style: dashed dashed solid dashed;
  border-color: #3a6cfe transparent transparent transparent;
}
.header_see {
  position: relative;
  width: 120px;
  height: 20px;
  margin-right: 5%;
}

.see_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.see_list li {
  font-size: 20px;
  color: black;
  cursor: pointer;
}
.code {
  position: absolute;
  top: 40px;
  left: 14px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  width: 86px;
  height: 86px;
  padding: 6px;
  overflow: hidden;
  z-index: 10;
}
.code .codepic {
  width: 100%;
  height: 100%;
}
.header_see:hover .code {
  display: block;
}
.search-info {
  width: 100%;
  // left: calc(50% - 16.875rem);
}
.search-input {
  position: absolute;
  width: 34.375rem;
  left: calc(50% - 16.875rem);
  // display: none;
  margin-top: 1%;
}
.search-input .exit {
  float: left;
  font-size: 2rem !important;
  width: 2.5rem;
  display: block;
  text-align: left;
  line-height: 3.5rem;
  cursor: pointer;
}
.search-input .exit .iconfont {
  font-size: 1.5rem !important;
}
.search-input .search-do {
  font-size: 1.5rem;
  // width: 2.5rem;
  display: block;
  float: right;
  line-height: 4.1rem;
}
.search-input input {
  // background-color: #FFE300;
  outline: none;
  border: none;
  height: 3.5rem;
  width: 29.375rem;
  line-height: 3.5rem;
  font-size: 1rem;
  float: left;
}
.search-more {
  position: relative;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2);
  margin-top: 3.5rem;
  text-align: left;
  background-color: #ffffff;
  z-index: 15;
}
.search-more li {
  height: 2.5rem;
  padding-left: 1.5rem;
  line-height: 2.5rem;
  font-size: 0.85rem;
  color: #bbbbbb;
}
.search-more li a {
  text-decoration: none;
  color: #999;
}
.search-more li:hover {
  background-color: #f2f2f2;
}
.search-more li:nth-child(1) {
  height: 2.5rem;
  line-height: 2.5rem;
  // padding-left: -20px;
  font-size: 0.75rem;
  color: #bbbbbb;
}
.search-more li:nth-child(1):hover {
  background-color: #ffffff;
}
.yf-icon-cha:before {
  font-size: 20px;
}
.active {
  color: #3a6cfe;
}
.active {
  color: #3a6cfe !important;
}
.active::after {
  content: "";
  display: block;
  color: #3a6cfe;
  position: absolute;
  top: 70%;
  left: 37%;
  width: 0;
  height: 0px;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  border: 8px;
  border-style: dashed dashed solid dashed;
  border-color: #3a6cfe transparent transparent transparent;
}
.menu {
  margin-right: 2%;
  display: none;
}
.yf-icon-caidan {
  font-size: 30px;
}
.menu_list {
  width: 100%;
}
.menu_list li {
  width: 100%;
  height: 50px;
  font-size: 18px/50px "微软雅黑";
  color: black;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
}
.menu_list li a {
  font-size: 18px/50px "微软雅黑";
  color: black;
  line-height: 50px;
}

.navnav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navnav li {
  width: 120px;
  margin-left: 2%;
  font: 14px/50px "微软雅黑";
  color: #888;
  cursor: pointer;
  text-align: center;
}
.navnav:hover {
  background: #fff;
}
.navnav li:hover {
  color: #3a6cfe;
  cursor: pointer;
}

.remove {
  display: none;
}

.el-row {
  z-index: 1000;
}
.xialacaidan span {
  font-size: 16px;
  color: black;
}
.xialacaidan:hover span {
  color: #3a6cfe;
}
.el-dropdown span {
  width: 100%;
}
.el-dropdown-link {
  cursor: pointer;
  // color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  width: 100%;
  display: block;
  color: #8492a6;
  font-size: 14px;
  // margin-bottom: 20px;
}
.el-dropdown-menu {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px !important;
  margin: -2px !important;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff !important;
  color: #3a6cfe !important;
}
@media screen and (min-width: 992px) {
  .header_list .hlist_content li:hover::after {
    left: 55px;
  }
  .active::after {
    left: 55px;
  }
  
  .xialacaidan .active::after {
    left: 8px;
  }
}
@media screen and (max-width: 992px) {
  .header_see {
    width: 15%;
    margin-right: 30px;
  }
  .code {
    left: 15%;
  }
  .logo {
    width: 10%;
  }
  .header_list {
    width: 50%;
  }
  .card_ul .card_li {
    width: 45%;
  }
  .c_list ul li {
    width: 45%;
  }
}
@media screen and (max-width: 768px) {
  .header_see {
    display: none;
  }
  .logo {
    width: 20%;
  }
  .header_list {
    width: 70%;
  }
  .card_ul .card_li {
    width: 100%;
  }
  .c_list ul li {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .header {
    height: 60px;
  }
  .header_see {
    display: none;
  }
  .logo {
    display: none;
  }
  .logo2 {
    display: block;
    width: 50%;
  }
  .header_list {
    width: 70%;
  }
  .card_ul .card_li {
    width: 100%;
  }
  .c_list ul li {
    width: 100%;
  }
  .header_list {
    display: none;
  }
  .menu {
    display: block;
  }
}
</style>