// 1.引入
import Vue from "vue"
import axios from "axios"
import qs from "query-string"
// 2.环境配置
//开发环境
if (process.env.NODE_ENV == "development") {
    Vue.prototype.$pre = "https://shanxiyf.com";//"http://localhost:3000"
}
//生产环境
if (process.env.NODE_ENV == "production") {
    Vue.prototype.$pre = "https://shanxiyf.com";
}
// 3.请求拦截
axios.interceptors.request.use(config => {
    return config
})
// 4.响应拦截
axios.interceptors.response.use(res => {
    // 统一打印
    console.group("本次请求地址：" + res.config.url)
    console.log(res);
    console.groupEnd()
    return res;
})
//get
/**
* get用来发起get请求的
* url:请求地址
* params:请求参数 默认是{}
* 案例：get("/api/login",{phone:"1",password:"12313"}).then()
*/
export const get = (url, params = {}) => {
    return axios({
        url,
        method: "get",
        params
    })
}
/**
* post用来发起post请求
* url:请求地址
* params:请求参数 默认{}
* isFile:是否有文件 true：有文件，false就是没有文件 默认是false
* 案例：post("/api/login",{phone:"1"},true).then(res=>{})
*/
export const post = (url, params = {}, isFile = false) => {
    let data = null
    if (isFile) {
        //有文件
        data = new FormData()
        for (let i in params) {
            data.append(i, params[i])
        }
    } else {
        //无文件
        data = qs.stringify(params)
    }
    return axios({
        url,
        method: "post",
        data
    })
}
